import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  HStack,
  Code,
  theme,
  Link,
  Flex,
} from '@chakra-ui/react';
import { Logo } from './Logo';
import { useSafeAppsSDK } from '@gnosis.pm/safe-apps-react-sdk';
import { ethers } from 'ethers';
import abi from './abi.json';
import { primary, secondary, accent, secondaryAccent } from './colors';
import Page from './components/page';

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const provider = new ethers.providers.InfuraProvider('homestead', process.env.REACT_APP_INFURA_KEY);
const contract = new ethers.Contract(contractAddress, abi, provider);

function App() {
  const [page, setPage] = useState(0);
  const { sdk, connected, safe } = useSafeAppsSDK();



  return (
    <ChakraProvider theme={theme}>
      <Text align={'center'}>
        You are <Code fontSize="xl">{connected ? "connected" : "not connected"}</Code> to safe <Code fontSize="xl">{safe.safeAddress}</Code>
      </Text>
      <Box background={secondary} textAlign="center" fontSize="xl">
        <HStack background={primary} p={4}>
          <Logo width='100px' ></Logo>
          <HStack color='blue' align='center' spacing={20} paddingLeft={20} >
            <Link onClick={() => setPage(0)} color={page === 0 ? secondary : 'white'}>Create funding round</Link>
            <Link onClick={() => setPage(1)} color={page === 1 ? secondary : 'white'}>Edit funding round</Link>
            <Link onClick={() => setPage(2)} color={page === 2 ? secondary : 'white'}>Withdraw to safe</Link>
          </HStack>
        </HStack>
        <Box paddingTop={20} minH='100vh' background={secondary}>
          <Flex justify='center'>
            <Page page={page} contract={contract} sdk={sdk} />
          </Flex>
        </Box>
      </Box>
    </ChakraProvider >
  );
}

export default App;

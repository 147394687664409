import React, { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  VStack,
  HStack,
  StackDivider,
} from '@chakra-ui/react';
import { primary, secondary, accent, secondaryAccent } from '../colors';
import { parseEther } from 'ethers/lib/utils';

function Page(props) {
  const [tokenIndex, setTokenIndex] = useState(0);
  const [mintPrice, setMintPrice] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [ipfsCID, setIpfsCID] = useState('');

  const page = props.page;
  const contract = props.contract;
  const sdk = props.sdk;

  const withdraw = async () => {
    const txs = [
      {
        to: contract.address,
        value: '0',
        data: contract.interface.encodeFunctionData('withdraw', []),
      },
    ];

    try {
      const txn = await sdk.txs.send({ txs });
      console.log(txn);
    } catch (e) {
      console.error(e.message);
    }
  };

  const createFundingRound = async () => {
    const startDateUnix = new Date(startDate).getTime() / 1000;
    const endDateUnix = new Date(endDate).getTime() / 1000;

    const txs = [
      {
        to: contract.address,
        value: '0',
        data: contract.interface.encodeFunctionData('createReidarToken', [
          parseEther(mintPrice),
          startDateUnix,
          endDateUnix,
          ipfsCID,
        ]),
      },
    ];

    const params = {
      safeTxGas: await contract.estimateGas.createReidarToken(
        parseEther(mintPrice),
        startDateUnix,
        endDateUnix,
        ipfsCID
      ),
    };

    try {
      const txn = await sdk.txs.send({ txs, params });
      console.log(txn);
    } catch (e) {
      console.error(e.message);
    }
  };

  const editFundingRound = async () => {
    const startDateUnix = Math.floor(new Date(startDate).getTime() / 1000);
    const endDateUnix = Math.floor(new Date(endDate).getTime() / 1000);

    const txs = [
      {
        to: contract.address,
        value: '0',
        data: contract.interface.encodeFunctionData('editReidarToken', [
          tokenIndex,
          parseEther(mintPrice),
          startDateUnix,
          endDateUnix,
          ipfsCID,
        ]),
      },
    ];

    const params = {
      safeTxGas: 0,
    };

    try {
      const txn = await sdk.txs.send({ txs, params });
      console.log(txn);
    } catch (e) {
      console.error(e.message);
    }
  };

  if (page === 0) {
    return (
      <Flex
        p="20"
        background={secondaryAccent}
        color={primary}
        padding={10}
        borderRadius={10}
      >
        <Flex direction={'column'}>
          <Text color="white">Create a new NFT</Text>
          <Flex p={5}>
            <HStack spacing={5}>
              <VStack spacing={7}>
                <Text color={secondary}>Mint Price</Text>
                <Text color={secondary}>Start Time</Text>
                <Text color={secondary}>End Time</Text>
                <Text color={secondary}>IPFS CID</Text>
              </VStack>
              <VStack>
                <Input
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setMintPrice(event.target.value);
                  }}
                />
                <Input
                  type="datetime-local"
                  _placeholder={{ color: 'lightgray' }}
                  placeholder="Start time"
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setStartDate(event.target.value);
                  }}
                />
                <Input
                  type="datetime-local"
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setEndDate(event.target.value);
                  }}
                />
                <Input
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setIpfsCID(event.target.value);
                  }}
                />
              </VStack>
            </HStack>
          </Flex>
          <Button marginTop={10} color={secondary} onClick={createFundingRound}>
            Create
          </Button>
        </Flex>
      </Flex>
    );
  } else if (page === 1) {
    return (
      <Flex
        p="20"
        background={secondaryAccent}
        color={primary}
        padding={10}
        borderRadius={10}
      >
        <Flex direction={'column'}>
          <Text color="white">Edit a current NFT</Text>
          <Flex p={5}>
            <HStack spacing={5}>
              <VStack spacing={7}>
                <Text color={secondary}>Token Index</Text>
                <Text color={secondary}>Mint Price</Text>
                <Text color={secondary}>Start Time</Text>
                <Text color={secondary}>End Time</Text>
                <Text color={secondary}>IPFS CID</Text>
              </VStack>
              <VStack>
                <Input
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setTokenIndex(event.target.value);
                  }}
                />
                <Input
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setMintPrice(event.target.value);
                  }}
                />
                <Input
                  type="datetime-local"
                  _placeholder={{ color: 'lightgray' }}
                  placeholder="Start time"
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setStartDate(event.target.value);
                  }}
                />
                <Input
                  type="datetime-local"
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setEndDate(event.target.value);
                  }}
                />
                <Input
                  size="lg"
                  color={secondary}
                  onChange={event => {
                    setIpfsCID(event.target.value);
                  }}
                />
              </VStack>
            </HStack>
          </Flex>
          <Button marginTop={10} color={secondary} onClick={editFundingRound}>
            Edit
          </Button>
        </Flex>
      </Flex>
    );
  } else if (page === 2) {
    return (
      <Flex justify="center" p="20">
        <Box
          background={secondaryAccent}
          color={primary}
          padding={10}
          borderRadius={10}
        >
          <Text color="white">
            Withdraws the eth from the smart contract to the safe
          </Text>
          <Button marginTop={10} color={secondary} onClick={withdraw}>
            Withdraw
          </Button>
        </Box>
      </Flex>
    );
  }
}

export default Page;
